@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  width: 100%;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F4F9;
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.container {
  width: 1040px;
  height: 424px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  display: flex;
  padding: 36px;
}

.left-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
}

.logo-container {
  width: 78px;
  height: 78px;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -20px;
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.text-container {
  margin-bottom: 24px;
}

.sign-in-text {
  color: #1f1f1f;
  font-weight: 400;
  font-size: 3rem;
  margin-bottom: 8px;
  margin-left: -7px;
}

.account-text {
  color: #1f1f1f;
  font-weight: 400;
  font-size: 1rem;
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.input-container {
  position: relative;
  width: 458px;
  height: 54px; /* Fixed height for the input container */
  margin-bottom: 1px; /* Ensure space below input container */
}

.input {
  width: 100%;
  height: 100%; /* Ensure input fills the container */
  padding: 13px 15px;
  border: 1px solid #747775; /* Updated border color */
  border-radius: 4px;
  font-size: 1rem;
  outline: none;
  transition: border-color 0.2s ease-in-out, border-width 0.2s ease-in-out;
  box-sizing: border-box;
}

.input:focus {
  border-color: #0b57d0;
  border-width: 2px;
}

.input-label {
  position: absolute;
  top: 14px;
  left: 16px;
  color: #1f1f1f;
  font-size: 1rem;
  pointer-events: none;
  transition: all 0.2s ease-in-out;
}

.input:focus::placeholder {
  color: transparent;
}

.input:focus + .input-label,
.input:not(:placeholder-shown) + .input-label {
  top: -12px;
  left: 14px;
  background-color: #fff;
  padding: 0 4px;
  font-size: 0.75rem;
  color: #0b57d0;
}

.input:focus + .input-label::before,
.input:not(:placeholder-shown) + .input-label::before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #fff;
  z-index: 1;
  transform: translateY(-50%);
}

.input-container.error .input {
  border-color: #B3261E;
}

.input-container.error .input-label {
  color: #B3261E;
}

.error-message {
  color: #B3261E;
  display: flex;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
}

.error-message .error-icon {
  margin-right: 8px;
}

.forgot-email {
  color: #0b57d0;
  font-weight: 500;
  text-decoration: none;
  margin-top: 1px;
}

.info-text {
  margin-top: 24px; /* Ensure proper spacing */
  margin-bottom: 24px;
  color: #444746;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286;
}

.learn-more {
  color: #0b57d0;
  font-weight: 500;
  text-decoration: none;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.create-account {
  margin-bottom: -287px;
  background: none;
  border: none;
  color: #0b57d0;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  margin-right: 100px;
  padding: 8px 16px;
  border-radius: 26px; /* Match the border-radius for consistency */
  transition: background-color 0.2s ease-in-out; /* Smooth transition effect */
}

.create-account:hover {
  background-color: #F7F9FD;
}

.next-button {
  margin-bottom: -10px;
  background-color: #0B57D0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 26px;
  transition: background-color 0.2s ease-in-out; /* Smooth transition effect */
}

.next-button:hover {
  background-color: #0E4EB9;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  html, body {
    height: 100%;
    width: 100%;
    background-color: #FFFFFF; /* Change background color */
  }

  body {
    justify-content: center; /* Center content vertically */
    align-items: center; /* Center content horizontally */
    padding: 10px; /* Add padding */
  }

  .container {
    width: 100%;
    height: auto; /* Allow height to adjust automatically */
    flex-direction: column;
    padding: 20px;
    border-radius: 0;
    box-shadow: none; /* Remove box shadow */
    align-items: center; /* Center the container content */
  }

  .left-section,
  .right-section {
    width: 100%;
    align-items: center; /* Center the sections */
    text-align: center; /* Center text alignment */
  }

  .logo-container {
    margin: 0 auto 20px;
  }

  .sign-in-text {
    text-align: center;
    font-size: 2.5rem; /* Adjust font size for smaller screens */
    margin-bottom: 8px; /* Add spacing below */
  }

  .account-text {
    text-align: center;
    font-size: 1rem; /* Adjust font size for smaller screens */
    margin-bottom: 16px; /* Add spacing below */
  }

  .input-group {
    width: 100%;
    align-items: center;
  }

  .input-container {
    width: 90%;
    margin-bottom: 8px; /* Ensure space below input container */
  }

  .info-text {
    text-align: center;
    margin-bottom: 16px; /* Add spacing below */
  }

  .actions {
    justify-content: center;
    margin-top: 16px;
  }

  .create-account, .next-button {
    margin-bottom: 0;
  }

  .create-account {
    margin-right: 10px;
  }
}
